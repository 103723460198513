import React, { ReactNode } from 'react'

import Header from '../header';
import Footer from '../footer';

import { Helmet } from 'react-helmet';

type layoutType = {
    children: ReactNode
    title: string
    description: string
    keywords: string
    link?: string
}

const Layout = ({ children, title, description, keywords, link }: layoutType) => {
  
  return (
    <>
    <Helmet>
      <title>{title} | Minha Sorte</title>
      <meta name="description" content={description}/>
      <meta name="keywords" content={`${keywords}, minhasorte, minha-sorte, minha sorte`}/>
      { link && <link rel="canonical" href={`https://www.minhasorte.store/${link}`}/> }
    </Helmet>
      <Header />
        <main style={{ maxWidth: '1000px', margin: 'auto'}}>
          {children}
        </main>
      <Footer />
    </>
  );
};

export default Layout;