import React, { memo, Suspense, useCallback, useEffect, useState } from 'react';
import Layout from '../../components/layout';
import { getLotteryContest } from '../../utils/contest';
import styles from '../../styles/Contest.module.css';
import { ContestResult, LoteriaType } from '../../utils/interfaces';
import Breadcrumb from '../../components/breadcrumb';
import { useParams } from 'react-router-dom';
import { arrayRange, cleanUrlParam, generateFakeArray, sum } from '../../utils/functions';
import { getDayOfWeek, formatDate } from '../../utils/date';
import { adsPopupBanner, adsShopee, appOfertasAds, hotmartAds } from '../../utils/ads';
import ProductAdBanner from '../../components/ProductAdProps';
import { generateLotteryContestData } from '../../utils/lotteries';
import PopupBanner from '../../components/PopupBanner';

const CourseAd = React.lazy(() => import('../../components/CourseAd'));
const LotofacilResume = React.lazy(() => import('../../components/Seo/lotofacil'));
const MegaSenaResume = React.lazy(() => import('../../components/Seo/megasena'));
const QuinaResume = React.lazy(() => import('../../components/Seo/quina'));
const LotomaniaResume = React.lazy(() => import('../../components/Seo/lotomania'));
const SuperSeteResume = React.lazy(() => import('../../components/Seo/supersete'));
const DiaDeSorteResume = React.lazy(() => import('../../components/Seo/diadesorte'));
const DuplaSenaResume = React.lazy(() => import('../../components/Seo/duplasena'));

const ContestPage = memo((): JSX.Element => {
  const { numero } = useParams();
  const { lotteryType } = useParams<{ lotteryType: LoteriaType | undefined }>();

  const validNumber = cleanUrlParam(String(numero));

  const [contest, setContest] = useState<ContestResult>();
  const [loading, setLoading] = useState<boolean>(true);
  const [dataFetched, setDataFetched] = useState<boolean>(false);

  const validLotteryType = !lotteryType ? 'lotofacil' : lotteryType

  useEffect(() => {
    if (!!validNumber?.length && !!validLotteryType?.length) {
      const fetchData = async () => {
        try {
          const result: ContestResult = await getLotteryContest(validLotteryType, parseInt(validNumber));
          setContest(result);
          setLoading(false);
        } catch (error) {
          setDataFetched(true);
        }
      };

      fetchData();
    }
  }, [validNumber]);

  const getContestWeekDay = contest?.dataApuracao ? getDayOfWeek(contest?.dataApuracao) : 'carregando';
  const getContestFormatDate = contest?.dataApuracao ? formatDate(contest?.dataApuracao) : '00 de carregando de 0000';

  // Gerando dados de SEO com base no número do concurso
  const contestData = generateLotteryContestData(validLotteryType, validNumber, getContestWeekDay, getContestFormatDate);

  const constestInfo = validLotteryType && contestData[validLotteryType] ? contestData[validLotteryType] : {
    title: "Loteria não encontrada",
    description: "Informações não disponíveis.",
    keywords: "loteria não encontrada",
    paths: [{ href: "/", label: "Home" }],
    loteriaName: "Loteria desconhecida",
    startPrize: "prêmio desconhecido",
    quantityOfDrawNumbers: 1,
    quantityOfPrizes: 1,
    listOfPrizes: "faixa de prêmios desconhecidas",
    listOfDrawDays: "dias de sorteio desconhecidos"
  };

  const title = constestInfo.title;
  const description = constestInfo.description;
  const keywords = constestInfo.keywords;

  const paths = constestInfo.paths;

  const checkIfIsLoadingOrContestNotExist = loading && !dataFetched ? 'Carregando...' : 'Não Sorteado';

  const fakeArrayResul: number[] = generateFakeArray(constestInfo.quantityOfDrawNumbers)
  const fakeArrayPrize: number[] = generateFakeArray(constestInfo.quantityOfPrizes)
  const arrayOfWeekDraw: number[] = arrayRange((Number(validNumber) - 7), (Number(validNumber) - 1), 1)

  const formatCash = useCallback((value: number | undefined) => {
    if (!value) {
      return 'R$ 00,00';
    }
    return value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }, []);

  const formatQuantity = useCallback((number: number) => {
    return new Intl.NumberFormat('pt-BR').format(number);
  }, []);

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: title,
        url: window.location.href
      })
        .then(() => {
          console.log("Compartilhado com sucesso!");
        })
        .catch((error) => {
          console.error("Erro ao compartilhar:", error);
        });
    } else {
      alert('Seu navegador não suporta a API de compartilhamento.');
    }
  };

  const handleCopy = (validNumber: string, listaDezenas: string[], isSecond: boolean = false) => {
    const validNumbers = listaDezenas.map(num => Number(num)).join(", ");
    const textoParaCopiar = `Resultado${isSecond ? ' Segundo Sorteio' : ''} ${constestInfo.loteriaName} Concurso ${validNumber}: ${validNumbers}`;

    navigator.clipboard.writeText(textoParaCopiar)
      .then(() => {
        alert('Números copiados para a área de transferência!');
      })
      .catch(err => {
        alert('Falha ao copiar números: ' + err);
      });
  };

  return (
    <Layout title={title} description={description} keywords={keywords} link={`${validLotteryType}/concurso/${validNumber}`}>
      {/* <PopupBanner banners={adsPopupBanner} /> */}
      <div className={styles.loterryPageContainer} style={{ padding: '1rem' }}>
        <Breadcrumb paths={paths} />
        <article>
          <div className={styles.titleContainer}>
            <h1 className={styles.contestPageTitle}>Resultado da {constestInfo.loteriaName} {validNumber}</h1>
            <button
              className={styles.shareButton}
              onClick={handleShare}
              title="Compartilhar"
            >
              📤 Compartilhar
            </button>
          </div>

          {(!contest?.dataApuracao && dataFetched) && (<a href={`/${validLotteryType}`} style={{ color: 'var(--dark-burgundy)', fontSize: '1.5rem', fontWeight: 'bold' }}>Aviso: O sorteio ainda não foi realizado. <br /> O resultado do sorteio estará disponível a partir das 21h30 da data do sorteio. <br /> Veja os resultados dos sorteios da semana.</a>)}
          <p>O sorteio do concurso <strong>{constestInfo.loteriaName} {validNumber}</strong> foi realizado na <strong>{getContestWeekDay}, {getContestFormatDate}</strong>, a partir das 20h (horário de Brasília), no <strong>Espaço da Sorte</strong>, em São Paulo. A premiação para os acertadores dos {constestInfo.quantityOfDrawNumbers} números sorteados começou a partir de <strong>R$ {constestInfo.startPrize}</strong>.</p>
          <p><strong>{constestInfo.loteriaName}</strong> é uma das modalidades mais populares da <strong>Caixa Econômica Federal</strong>. Este concurso também premiou aqueles que acertaram {constestInfo.listOfPrizes}, com valores proporcionais aos acertos.</p>
        </article>

        <Suspense fallback={<div>Carregando o Conteúdo...</div>}>
          <ProductAdBanner ads={adsShopee(validNumber)} />
        </Suspense>

        <article className={styles.drawnNumbersContainer}>
          <h2 id='validNumbers-sorteados'>Números Sorteados no Concurso {constestInfo.loteriaName} {validNumber}:</h2>
          <p>Os números sorteados no concurso <strong>{constestInfo.loteriaName} {validNumber}</strong>, realizado em <strong>{getContestFormatDate}</strong>, são:</p>
          <div className={`${styles.drawnNumbersWrapper} ${[validLotteryType]}DrawnNumbers`}>
            {loading ? (
              fakeArrayResul.map((_, index) => (
                <div
                  className={styles.drawnNumbers}
                  key={index}
                  style={{ width: '50px', height: '50px', backgroundColor: '#e0e0e0' }}  // Tamanho fixo e cor de fundo para carregamento
                >
                  ?
                </div>
              ))
            ) : (
              contest?.listaDezenas.map((num) => (
                <div
                  className={styles.drawnNumbers}
                  key={num}
                  style={{ width: '50px', height: '50px' }}  // Tamanho fixo para números
                >
                  {num}
                </div>
              ))
            )}
          </div>

          <button
            onClick={() => handleCopy(validNumber || '', contest?.listaDezenas || [])}
            className={styles.copyButton}
          >
            Copiar Números
          </button>

          {validLotteryType === 'duplasena' && (
            <>
              <h2 style={{ marginTop: '1rem' }} id='validNumbers-sorteados'>Números Segundo Sorteados no Concurso {constestInfo.loteriaName} {validNumber}:</h2>
              <p>Os números sorteados no concurso <strong>{constestInfo.loteriaName} {validNumber}</strong>, realizado em <strong>{getContestFormatDate}</strong>, são:</p>
              <div className={`${styles.drawnNumbersWrapper} ${[validLotteryType]}DrawnNumbers`}>
                {loading ? (
                  fakeArrayResul.map((_, index) => (
                    <div
                      className={styles.drawnNumbers}
                      key={index}
                      style={{ width: '50px', height: '50px', backgroundColor: '#e0e0e0' }}  // Tamanho fixo e cor de fundo para carregamento
                    >
                      ?
                    </div>
                  ))
                ) : (
                  contest?.listaDezenasSegundoSorteio && contest?.listaDezenasSegundoSorteio.map((num) => (
                    <div
                      className={styles.drawnNumbers}
                      key={num}
                      style={{ width: '50px', height: '50px' }}  // Tamanho fixo para números
                    >
                      {num}
                    </div>
                  ))
                )}
              </div>

              <button
                onClick={() => handleCopy(validNumber || '', contest?.listaDezenasSegundoSorteio || [], true)}
                className={styles.copyButton}
              >
                Copiar Números
              </button>
            </>)}
        </article>

        <Suspense fallback={<div>Carregando o Conteúdo...</div>}>
          <CourseAd title="🤑 Precisa economizar? Clique e Cadastre-se!!! 💰" discount='Até 30% OFF nas compras' ads={appOfertasAds(validNumber)} />
        </Suspense>
        
        <article>
          <h2 id='apostas-premiadas'>Confira quantas apostas foram premiadas no concurso {validNumber}:</h2>
          <div className={styles.contestTable}>
            <table>
              <thead>
                <tr>
                  <th>Pontos</th>
                  <th>Faixa</th>
                  <th>Ganhadores</th>
                  <th>Prêmio (R$)</th>
                </tr>
              </thead>
              <tbody>
                {loading ? fakeArrayPrize.map((_, index) =>
                  <tr key={index}>
                    <td>{checkIfIsLoadingOrContestNotExist}</td>
                    <td>{checkIfIsLoadingOrContestNotExist}</td>
                    <td>{checkIfIsLoadingOrContestNotExist}</td>
                    <td>{checkIfIsLoadingOrContestNotExist}</td>
                  </tr>
                ) :
                  contest?.listaRateioPremio && contest.listaRateioPremio.map(({ descricaoFaixa, faixa, numeroDeGanhadores, valorPremio }) =>
                    <tr key={descricaoFaixa}>
                      <td>{descricaoFaixa}</td>
                      <td>{faixa}º</td>
                      <td>{formatQuantity(numeroDeGanhadores)}</td>
                      <td>{formatCash(valorPremio)}</td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        </article>
        <Suspense fallback={<div>Carregando o Conteúdo...</div>}>
          <CourseAd ads={hotmartAds(validNumber)} />
        </Suspense>
        <article>
          <h2 id='cidades-ganhadoras'>Cidades Ganhadoras no Concurso {constestInfo.loteriaName} {validNumber}:</h2>
          <div className={styles.contestTable}>
            <table>
              <thead>
                <tr>
                  <th>Acerto</th>
                  <th>Ganhadores</th>
                  <th>Município</th>
                  <th>UF</th>
                </tr>
              </thead>
              <tbody>
                {!loading && contest?.listaMunicipioUFGanhadores.length === 0 && (
                  <tr>
                    <td>0</td>
                    <td>NÃO HOUVE ACERTADORES DE {constestInfo.quantityOfDrawNumbers} DEZENAS NESSE CONCURSO</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                )}
                {loading ? fakeArrayPrize.map((_, index) =>
                  <tr key={index}>
                    <td>{checkIfIsLoadingOrContestNotExist}</td>
                    <td>{checkIfIsLoadingOrContestNotExist}</td>
                    <td>{checkIfIsLoadingOrContestNotExist}</td>
                    <td>{checkIfIsLoadingOrContestNotExist}</td>
                  </tr>
                ) :
                  contest?.listaMunicipioUFGanhadores
                  && contest.listaMunicipioUFGanhadores
                    .map(({ ganhadores, municipio, uf }) =>
                      <tr key={municipio}>
                        <td>{constestInfo.quantityOfDrawNumbers} pontos</td>
                        <td>{ganhadores}</td>
                        <td>{!!municipio.length ? municipio : 'NÃO IDENTIFICADO'}</td>
                        <td>{uf}</td>
                      </tr>
                    )}
              </tbody>
            </table>
          </div>
        </article>
        <Suspense fallback={<div>Carregando o Conteúdo...</div>}>
          <ProductAdBanner ads={adsShopee(validNumber)} />
        </Suspense>

        <article>
          <h2 id='receber-premio'>Como Receber o Prêmio da {constestInfo.loteriaName}</h2>
          <p>Os ganhadores da <strong>{constestInfo.loteriaName}</strong> podem resgatar seus prêmios em casas lotéricas credenciadas ou nas agências da <strong>Caixa Econômica Federal</strong>. Para prêmios acima de <strong>R$ 1.903,98</strong>, o resgate deve ser feito exclusivamente nas agências da Caixa, com apresentação de documento de identidade original com CPF e o recibo de aposta premiado.</p>
          <p>Há uma regra importante: para prêmios iguais ou superiores a <strong>R$ 10 mil</strong>, o pagamento será feito em até dois dias após a apresentação do bilhete premiado em uma agência da Caixa. Fique atento aos prazos para garantir o seu prêmio!</p>
        </article>

        <article>
          <h2 id='proximo-sorteio'>Próximo Sorteio: {constestInfo.loteriaName} {sum(validNumber, 1)}</h2>
          <p>O <a href={`/${validLotteryType}/concurso/${sum(validNumber, 1)}`}>próximo sorteio da <strong>{constestInfo.loteriaName}</strong> será o de número <strong>{sum(validNumber, 1)}</strong> {contest?.dataProximoConcurso && `no dia ${contest?.dataProximoConcurso}`}</a>. Os sorteios da {constestInfo.loteriaName} ocorrem regularmente em 6 dias da semana: <strong>segunda-feira</strong>, <strong>terça-feira</strong>, <strong>quarta-feira</strong>, <strong>quinta-feira</strong>, <strong>sexta-feira</strong> e <strong>sábado</strong>, sempre às <strong>20 horas</strong>, salvo em datas especiais, como feriados, quando podem ser adiados ou suspensos. Fique atento aos comunicados oficiais da <strong>Caixa Econômica Federal</strong> sobre possíveis alterações na data dos sorteios devido a feriados nacionais ou outras condições excepcionais.</p>
        </article>

        <article className={styles.weekDraw}>
          <h2 id='sorteios-semana'>Resultados da {constestInfo.loteriaName} de Ontem e Sorteios da Semana:</h2>
          {
            arrayOfWeekDraw.reverse().map((contest: any) => (
              <p><a href={`/${validLotteryType}/concurso/${contest}`}>Resultado {constestInfo.loteriaName} Concurso {contest}</a></p>
            ))
          }
        </article>
        <Suspense fallback={<div>Carregando o Conteúdo...</div>}>
          {validLotteryType === 'lotofacil' && (<LotofacilResume />)}
          {validLotteryType === 'megasena' && (<MegaSenaResume />)}
          {validLotteryType === 'quina' && (<QuinaResume />)}
          {validLotteryType === 'lotomania' && (<LotomaniaResume />)}
          {validLotteryType === 'diadesorte' && (<DiaDeSorteResume />)}
          {validLotteryType === 'duplasena' && (<DuplaSenaResume />)}
          {validLotteryType === 'supersete' && (<SuperSeteResume />)}
        </Suspense>
      </div>
    </Layout>

  )
})

export default ContestPage;
