import React, { lazy, useEffect, useState, Suspense } from 'react';
import useIsMobile from '../../hooks/useIsMobile';
import styles from '../../styles/ProductAd.module.css'; // Importando o CSS Module

interface ProductAd {
  image: string;
  link: string;
  title: string;
  label: string;
  page: string;
  alt?: string;
  startDate?: string;
  endDate?: string;
  oldPrice: number;
  newPrice: number;
  discountCoupons: string[];
  additionalInfo?: string[];
  ratings?: {
    rating: number;
    reviews: number;
    sold: number;
  };
}

interface ProductAdProps {
  ads: ProductAd[];
}

const LazyImage = lazy(() => import('../lazyImage'));

// Function to determine the number of columns based on screen size
const getColumnCount = (): number => {
  const width = window.innerWidth;
  if (width >= 1200) return 3;
  if (width >= 992) return 3;
  if (width >= 768) return 2;
  return 1;
};

const truncateTitle = (title: string, length: number = 30): string => {
  if (title.length > length) {
    return title.substring(0, length) + '...';
  }
  return title;
};

// Function to render the stars for product ratings
const renderStars = (rating: number) => {
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(<span key={i} className={styles.stars}>★</span>);
    } else {
      stars.push(<span key={i} className={styles.stars}>☆</span>);
    }
  }
  return stars;
};

// Helper function to check if a given ad is within its start and end date range
const isValidDateRange = (startDate?: string, endDate?: string): boolean => {
  const today = new Date();
  if (startDate && endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return today >= start && today <= end;
  }
  return true; // If no start or end date is provided, consider the ad valid
};

// Function to check if an ad is part of a specific flag (like Black Friday)
const isFlagActive = (flag: string, endDate?: string): boolean => {
  const today = new Date();
  const flagEndDate = endDate ? new Date(endDate) : new Date();
  
  // Here we can customize the flag conditions
  if (flag === "Black Friday" && flagEndDate) {
    return today <= flagEndDate; // Black Friday ends on the specified date
  }
  // You can add more flags here (like Cyber Monday, etc.)
  return false;
};

const ProductAdBanner: React.FC<ProductAdProps> = ({ ads }) => {
  const [displayAds, setDisplayAds] = useState<ProductAd[]>([]);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (ads.length > 0) {
      // Filter ads based on whether the flag is active
      // const validAds = ads.filter(ad => isValidDateRange(ad.startDate, ad.endDate) && isFlagActive("Black Friday", "2024-12-03"));
      const validAds = ads.filter(ad => isValidDateRange(ad.startDate, ad.endDate));
      
      const columnCount = getColumnCount();
      const shuffledAds = validAds.sort(() => 0.5 - Math.random()).slice(0, columnCount);
      setDisplayAds(shuffledAds);
    }
  }, [ads, isMobile]);

  return (
    <div className={styles.productAdBanner} style={{ gridTemplateColumns: `repeat(${getColumnCount()}, 1fr)` }}>
      {displayAds.map((ad, index) => (
        <div key={index} className={styles.productAd}>
          <a href={ad.link} target="_blank" rel="noopener noreferrer">
            <div style={{display: 'flex', flexWrap: 'wrap', gap: '0.5rem', marginBottom: '0.5rem'}}>
              <span className={styles.discount}>Desconto: {Math.round(((ad.oldPrice - ad.newPrice) / ad.oldPrice) * 100)}%</span>
              <span className={styles.discount} style={{ background: 'var(--dark-orange)'}}>Shopee</span>
              {/* {isFlagActive("Black Friday", "2024-12-03") && (
                <span className={styles.discount} style={{ background: 'var(--dark-gray-almost-black)' }}>Black Friday</span>
              )} */}
            </div>

            {ad.ratings && (
              <div className={styles.rating}>
                <div className={styles.stars}>Nota: {renderStars(ad.ratings.rating)}</div>
                <span>{ad.ratings.rating} / 5</span>
              </div>
            )}

            <Suspense fallback={<div>Loading...</div>}>
              <LazyImage src={ad.image} alt={ad.title} className={styles.productImage} />
            </Suspense>

            <div className={styles.productDetails}>
              <p><strong>{truncateTitle(ad.title, 30)}</strong></p>
              <p className={styles.oldPrice}>De: {ad.oldPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
              <p className={styles.newPrice}>Por: {ad.newPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>

              {ad.discountCoupons.length > 0 && (
                <div className={styles.coupons}>
                  <h2>Cupons de Desconto:</h2>
                  <ul>
                    {ad.discountCoupons.map((coupon, idx) => (
                      <li key={idx}>{truncateTitle(coupon, 23)}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};

export default ProductAdBanner;
