import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../components/layout';
import Breadcrumb from '../../components/breadcrumb';
import { getAllLotteryContest } from '../../utils/contest';
import { generateFakeArray } from '../../utils/functions';
import styles from '../../styles/Home.module.css';
import { LoteriaType } from '../../utils/interfaces';

// Importando as informações das loterias
import { loterries } from '../../utils/lotteries';

const ConstestIcon = lazy(() => import('../../components/ConstestIcon'));
const LotofacilResume = React.lazy(() => import('../../components/Seo/lotofacil'));
const MegaSenaResume = React.lazy(() => import('../../components/Seo/megasena'));
const QuinaResume = React.lazy(() => import('../../components/Seo/quina'));
const LotomaniaResume = React.lazy(() => import('../../components/Seo/lotomania'));
const SuperSeteResume = React.lazy(() => import('../../components/Seo/supersete'));
const DiaDeSorteResume = React.lazy(() => import('../../components/Seo/diadesorte'));
const DuplaSenaResume = React.lazy(() => import('../../components/Seo/duplasena'));

const LotteryHome: React.FC = () => {
  const [contests, setContests] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(6);  // Variável para itens por página
  
  // Usando o hook useParams para acessar o lotteryType da URL
  const { lotteryType } = useParams<{ lotteryType: LoteriaType | undefined }>();

  const fetchContests = useCallback(async () => {
    const fetchedContests = await getAllLotteryContest(lotteryType);
    setContests(fetchedContests);
    setLoading(false);
  }, [lotteryType]);

  useEffect(() => {
    fetchContests();
  }, [fetchContests]);

  useEffect(() => {
    // Detectando o tamanho da tela e ajustando o número de itens por página
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setItemsPerPage(3);  // Para telas pequenas (mobile)
      } else {
        setItemsPerPage(6);  // Para telas grandes (desktop)
      }
    };

    // Inicializa a verificação do tamanho da tela
    handleResize();

    // Adiciona o ouvinte de redimensionamento
    window.addEventListener('resize', handleResize);

    // Limpa o ouvinte de redimensionamento quando o componente for desmontado
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fakeArray: number[] = generateFakeArray(itemsPerPage);

  // Recuperando os dados da loteria com base no lotteryType
  const lotteryInfo = lotteryType && loterries[lotteryType] ? loterries[lotteryType] : {
    title: "Loteria não encontrada",
    description: "Informações não disponíveis.",
    keywords: "loteria não encontrada",
    paths: [{ href: "/", label: "Home" }],
    loteriaName: "Loteria desconhecida",
    contestUrl: "/",
    loteriaColor: "#000000"
  };

  // Função para calcular os concursos que devem ser exibidos na página atual
  const indexOfLastContest = currentPage * itemsPerPage;
  const indexOfFirstContest = indexOfLastContest - itemsPerPage;
  const currentContests = contests.slice(indexOfFirstContest, indexOfLastContest);

  // Função para gerar números de páginas, com elipses
  const pageNumbers = [];
  const totalPages = Math.ceil(contests.length / itemsPerPage);

  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else {
    const range = 2; // Número de páginas antes e depois da página atual
    let start = Math.max(currentPage - range, 1);
    let end = Math.min(currentPage + range, totalPages);

    if (start > 2) {
      pageNumbers.push(1, '...');
    }
    for (let i = start; i <= end; i++) {
      pageNumbers.push(i);
    }
    if (end < totalPages - 1) {
      pageNumbers.push('...');
    }
    if (end < totalPages) {
      pageNumbers.push(totalPages);
    }
  }

  return (
    <Layout title={lotteryInfo.title} description={lotteryInfo.description} keywords={lotteryInfo.keywords}>
      <div style={{ padding: '1rem' }}>
        <Breadcrumb paths={lotteryInfo.paths} />
        <h1 style={{ marginTop: '0.5rem' }} className="subtitle">
          Resultado de Todos os Concursos da {lotteryInfo.loteriaName}:
        </h1>
        <p>
          Informações de todos os sorteios/concursos da {lotteryInfo.loteriaName} de hoje, ontem e do mês inteiro (Segunda, Terça, Quarta, Quinta, Sexta e Sábado)
        </p>

        <section className={styles.contestsContainer}>
          <h2>Últimos resultados da {lotteryInfo.loteriaName}</h2>
          <div>
            {loading ? fakeArray.map((_, index) => (
              <div className={styles.contestsResultLink} key={index}>
                <Suspense fallback='Imagem Carregando...'>
                  <ConstestIcon />
                </Suspense>
                <p>
                  <span>Resultado {lotteryInfo.loteriaName} Concurso ...</span>
                  <span>Confira os Números Sorteados e Ganhadores</span>
                </p>
              </div>
            )) :
              currentContests.map((contest: string) => (
                <a href={`${lotteryInfo.contestUrl}${contest}`} className={styles.contestsResultLink}
                  style={{
                    borderColor: lotteryInfo.loteriaColor,
                    textDecorationColor: lotteryInfo.loteriaColor,
                    color: lotteryInfo.loteriaColor
                  }} 
                  key={`${lotteryInfo.contestUrl}-${contest}`}>
                  <Suspense fallback='Imagem Carregando...'>
                    <ConstestIcon color={lotteryInfo.loteriaColor} />
                  </Suspense>
                  <p>
                    <strong>Resultado {lotteryInfo.loteriaName} Concurso {contest}</strong>
                    <span>Confira os Números Sorteados e Ganhadores</span>
                  </p>
                </a>
              ))}
          </div>
        </section>

        {/* Paginação */}
        <div className={styles.pagination}>
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className={styles.pageButton}
          >
            Anterior
          </button>
          {pageNumbers.map((number, index) => (
            <button
              key={index}
              onClick={() => {
                if (number !== '...') setCurrentPage(number as number);
              }}
              className={`${styles.pageButton} ${currentPage === number ? styles.active : ''}`}
              disabled={number === '...'}
            >
              {number}
            </button>
          ))}
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={styles.pageButton}
          >
            Próximo
          </button>
        </div>

        <Suspense fallback={<div>Carregando o Conteúdo...</div>}>
          {lotteryType === 'lotofacil' && (<LotofacilResume />)}
          {lotteryType === 'megasena' && (<MegaSenaResume />)}
          {lotteryType === 'quina' && (<QuinaResume />)}
          {lotteryType === 'lotomania' && (<LotomaniaResume />)}
          {lotteryType === 'diadesorte' && (<DiaDeSorteResume />)}
          {lotteryType === 'duplasena' && (<DuplaSenaResume />)}
          {lotteryType === 'supersete' && (<SuperSeteResume />)}
        </Suspense>
      </div>
    </Layout>
  );
}

export default LotteryHome;
