import { arrayRange } from "./functions";
import { ConstestNumber, ContestResult, LoteriaType } from "./interfaces";

export const getAllLotteryContest = async (lotteryType: LoteriaType = 'lotofacil') => {

  let contest: ConstestNumber = {
    numero: 0
  }

  // Definindo a chave do cache com base no tipo de loteria
  const cacheKey = `${lotteryType}Contests`;

  // Check for cached data in sessionStorage
  const cachedData = sessionStorage.getItem(cacheKey);
  if (cachedData) {
    try {
      const parsedData = JSON.parse(cachedData);

      return parsedData; // Return cached data
    } catch (error) {
      console.error(`Erro ao parsear os dados em cache para ${lotteryType}:`, error);
      // Consider clearing the cache or handling errors differently
    }
  }

  const fallbackUrl2 = `https://api.guidi.dev.br/loteria/${lotteryType}/ultimo`;
  try {

    const fallbackResponse2 = await fetch(fallbackUrl2);
    if (!fallbackResponse2.ok) {
      throw new Error(`${lotteryType} API request failed`);
    }
    const fallbackData2: ContestResult = await fallbackResponse2.json();


    contest = fallbackData2
  } catch (error) {

    try {
      const cefUrl = 'https://servicebus2.caixa.gov.br/portaldeloterias/api/home/ultimos-resultados';
      const cefResponse = await fetch(cefUrl);
      if (!cefResponse.ok) {
        throw new Error('CEF "ultimos-resultados" API request failed');
      }
      const cefData: { [key: string]: { numeroDoConcurso: number } } = await cefResponse.json();
      contest.numero = cefData[lotteryType]?.numeroDoConcurso || 0;
    } catch (error) {
      throw new Error(`Falha ao buscar o concurso da loteria ${lotteryType}: ${error}`);
    }
  }

  const contestsQuantity = contest?.numero - 1
  const arrayOfContest = arrayRange((contest?.numero - contestsQuantity), contest?.numero, 1)

  sessionStorage.setItem(cacheKey, JSON.stringify(arrayOfContest.reverse()));

  return !!arrayOfContest.length ? arrayOfContest : [];
}

export const getLotteryContest = async (
  lotteryType: LoteriaType, // Tipo de loteria (ex: 'lotofacil', 'megasena', etc.)
  contestNumber: number    // Número do concurso
): Promise<ConstestNumber | {}> => {
  const cacheKey = `${lotteryType}-${contestNumber}`; // Define uma chave de cache baseada no tipo de loteria e número do concurso
  
  // Check for cached data in sessionStorage
  const cachedData = sessionStorage.getItem(cacheKey);
  if (cachedData) {
    try {
      const parsedData = JSON.parse(cachedData);

      return parsedData; // Return cached data
    } catch (error) {
      console.error(`Erro ao parsear os dados em cache para ${lotteryType}:`, error);
    }
  }

  try {
    const guidiDevResponse = await fetch(
      `https://api.guidi.dev.br/loteria/${lotteryType}/${contestNumber}`
    );

    if (!guidiDevResponse.ok) {
      throw new Error(`${lotteryType} API request failed`);
    }

    const guidiDevData: ConstestNumber = await guidiDevResponse.json();

    sessionStorage.setItem(cacheKey, JSON.stringify({ ...guidiDevData, timestamp: Date.now() })); // Add timestamp for expiration (optional)

    return !!guidiDevData.numero ? guidiDevData : {};
  } catch (error) {
    try {
      const cefUrl = `https://servicebus2.caixa.gov.br/portaldeloterias/api/${lotteryType}/${contestNumber}`;
      const cefResponse = await fetch(cefUrl);
      if (!cefResponse.ok) {
        throw new Error('CEF API request failed');
      }
      const cefData: ConstestNumber = await cefResponse.json();

      // Armazenando os dados no sessionStorage com timestamp
      sessionStorage.setItem(cacheKey, JSON.stringify({ ...cefData, timestamp: Date.now() }));

      return cefData.numero ? cefData : {}; // Retorna o objeto de dados ou um objeto vazio
    } catch (error) {
      throw new Error(`Falha ao buscar o concurso ${lotteryType}: ${error}`);
    }
  }
};